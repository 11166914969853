:root {
  --border-color: #195fe9; /* Moovs Blue */
}

.image-gallery-thumbnail.active {
  border-color: var(--border-color);
}

.image-gallery-thumbnail:hover {
  border-color: var(--border-color);
}
