.image-gallery-bullets .image-gallery-bullet {
  background: #c9c7c3;
  border-color: #c9c7c3;
}
.image-gallery-bullets .image-gallery-bullet:hover {
  background: #fff;
  border-color: #fff;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background: #fff;
  border-color: #fff;
}

.image-gallery-image {
  border-radius: 4px;
  min-height: 270px;
  background-color: #d3d3d3;
}

@media (min-width: 600px) {
  .image-gallery-image {
    max-width: 400px;
  }
}
